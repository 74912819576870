import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.slice.js";
//
//
//
//
//
export default {
  props: {
    //内容
    printerInfo: {
      type: String,
      default: ""
    },
    //速度
    duration: {
      type: Number,
      default: 100
    },
    //延迟
    delay: {
      type: Number,
      default: 3000
    },
    working: {
      type: Boolean,
      default: true
    },
    once: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      content: "",
      cursor: 0,
      timer: null,
      timeout: null,
      print: true
    };
  },
  created: function created() {
    if (this.working) {
      //this.work：引用函数
      //this.work()：执行函数
      this.start(this.work);
    } else {
      this.content = this.printerInfo;
    }
  },
  watch: {
    working: function working(newVal) {
      this.toBegin();
    },
    printerInfo: function printerInfo(newVal) {
      this.toBegin();
    },
    cursor: function cursor(_cursor) {
      //slice(start,end)：不包含end
      this.content = this.printerInfo.slice(0, _cursor);
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    /**
     * 定时
     */
    start: function start(work) {
      var _this = this;

      //延迟
      this.timeout = setTimeout(function () {
        //速度
        _this.timer = setInterval(function () {
          work();

          if (_this.cursor === 0 || _this.cursor === _this.printerInfo.length && !_this.once) {
            //此处为了延迟
            clearInterval(_this.timer);

            _this.start(_this.work);
          } else if (_this.cursor === _this.printerInfo.length && _this.once) {
            clearInterval(_this.timer);
          }
        }, _this.duration);
      }, this.delay);
    },

    /**
     * 逻辑
     */
    work: function work() {
      var cursor = this.cursor;
      cursor += this.print ? 1 : -1;

      if (this.print) {
        if (cursor === this.printerInfo.length + 1) {
          cursor -= 2;
          this.print = !this.print;
        }
      } else {
        if (cursor === -1) {
          cursor += 2;
          this.print = !this.print;
        }
      }

      this.cursor = cursor;
    },
    toBegin: function toBegin() {
      this.cursor = 0;

      if (this.timeout !== null) {
        clearTimeout(this.timeout);

        if (this.timer !== null) {
          clearInterval(this.timer);
        }
      }

      if (this.working) {
        this.start(this.work);
      } else {
        this.content = this.printerInfo;
      }
    }
  }
};